<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row width="100%" class="pb-1 pa-0 ma-0">
      <v-col cols="2" class="pr-2 pa-0 ma-0" align="end">
        <v-container fluid class="ma-0 pa-0">
          <v-img
            v-if="level == 2"
            :src="require('@/assets/img/refresh-icon.svg')"
            max-width="30px"
            @click="tolevelOne()"
            style="position: absolute; cursor: pointer"
          />
          <span class="roboto small-font-title">Proyectos</span>
        </v-container>
      </v-col>
      <v-col cols="10" class="pa-0 ma-0">
        <v-row width="100%" class="ma-0 pa-0 border-bottom-gray">
          <v-col align="end" class="pa-0 ma-0" v-for="counter in maxProject" :key="counter">
            <span class="roboto small-font-number">{{ counter }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <transition name="slide-fade-top">
      <v-container v-if="level == 1" fluid class="pa-0 ma-0">
        <v-row v-for="(element, index) in data.children" :key="index" width="100%" class="py-1 pa-0 ma-0">
          <v-col cols="2" class="pr-2 pa-0 ma-0" align="end" align-self="center">
            <span @click="tolevelTwo(element)" style="cursor: pointer" class="roboto small-font">{{
              element.name
            }}</span>
          </v-col>
          <v-col cols="10" height="35px" align-self="center" class="pa-0 ma-0">
            <v-row width="100%" align="stretch" class="ma-0 pa-0">
              <v-progress-linear
                style="cursor: pointer"
                @click="tolevelTwo(element)"
                :value="getPercentage(element)"
                height="60px"
                background-color="transparent"
                color="#7319d5"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </transition>
    <transition name="slide-fade">
      <v-container v-if="level == 2" fluid class="pa-0 ma-0">
        <v-row v-for="(element, index) in children.children" :key="index" width="100%" class="py-1 pa-0 ma-0">
          <v-col cols="2" class="pr-2 pa-0 ma-0" align="end" align-self="center">
            <span class="roboto small-font">{{ element.name }}</span>
          </v-col>
          <v-col cols="10" height="35px" align-self="center" class="pa-0 ma-0">
            <v-row width="100%" align="stretch" class="ma-0 pa-0">
              <v-progress-linear
                :value="getPercentageChildren(element)"
                height="60px"
                background-color="transparent"
                color="#7319d5"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </transition>
  </v-container>
</template>

<script>
export default {
  name: "HierarchicalBarChart",

  props: {
    data: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: () => 500,
    },
  },

  data() {
    return {
      maxProject: 0,
      level: 1,
      children: [],
    };
  },

  created() {
    this.data.children.forEach((children) => {
      var temp = 0;
      children.children.forEach((project) => {
        temp += project.value;
      });
      if (temp > this.maxProject) {
        this.maxProject = temp;
      }
    });
  },

  methods: {
    getPercentage(children) {
      var temp = 0;
      children.children.forEach((e) => {
        temp += e.value;
      });
      return (temp * 100) / this.maxProject;
    },
    getPercentageChildren(children) {
      return (children.value * 100) / this.maxProject;
    },
    tolevelTwo(children) {
      var maxTemp = 0;
      children.children.forEach((e) => {
        if (e.value > maxTemp) {
          maxTemp = e.value;
        }
      });
      this.level = 2;
      this.maxProject = maxTemp;
      this.children = children;
    },
    tolevelOne() {
      this.data.children.forEach((children) => {
        var temp = 0;
        children.children.forEach((project) => {
          temp += project.value;
        });
        if (temp > this.maxProject) {
          this.maxProject = temp;
        }
      });
      this.children = [];
      this.level = 1;
    },
  },
};
</script>

<style>
.show-icon {
  opacity: 1;
  transition-duration: 200ms;
  transition-property: opacity;
  transition-delay: 200ms;
}
.border-bottom-gray {
  border-bottom: 1px solid #dedede;
}
.hide-icon {
  opacity: 0;
  transition-duration: 200ms;
  transition-property: opacity;
  transition-delay: 200ms;
}
.small-font {
  font-size: 11px;
  line-height: 10pt !important;
  display: inline-block !important;
}
.small-font-title {
  font-size: 12px;
  line-height: 10pt !important;
  display: inline-block !important;
}
.small-font-number {
  font-size: 11px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-top-enter-active {
  transition: all 1s ease;
}
.slide-fade-top-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-top-enter, .slide-fade-top-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
