<template>
  <div />
</template>

<script>
import ZoomableCirclePackingChart from "@/plugins/d3/zoomable-circle-packing-chart.js";

export default {
  name: "ZoomableCirclePackingChart",

  props: {
    data: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: () => 500,
    },
  },

  mounted() {
    if (this.data.children) {
      ZoomableCirclePackingChart(this.$el, {
        data: this.data,
        width: this.width,
      });
    }
  },
};
</script>
