import uaaAxios from "@/utils/authAxios";

const RankingService = {
  /**
   *
   * @returns
   */
  getByTrajectory: async () => {
    const { data } = await uaaAxios.get(`${process.env.VUE_APP_COMPANIES_API}/categories/round-graphic`);
    return data;
  },
  /**
   *
   * @returns
   */
  getByNumberOfProjects: async () => {
    const { data } = await uaaAxios.get(`${process.env.VUE_APP_COMPANIES_API}/sectors/ranking-by-projects`);
    return data;
  },
  /**
   *
   * @returns
   */
  getBySize: async () => {
    const { data } = await uaaAxios.get(`${process.env.VUE_APP_COMPANIES_API}/sectors/specialists-by-sector`);
    return data;
  },
};

export default RankingService;
