<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <Loader :visible="isLoading" />
    <div v-if="!isLoading" class="ranking-title">Ranking de Especialistas</div>
    <v-tabs v-if="!isLoading" v-model="tab" color="tway-violet" class="my-5" show-arrows>
      <v-tabs-slider color="tway-apple-green" />
      <v-tab class="tabs-title"> Servicio a la Medida </v-tab>
      <v-tab class="tabs-title"> Experiencia en el Sector </v-tab>
      <v-tab class="tabs-title"> Tamaño </v-tab>
    </v-tabs>
    <v-tabs-items v-if="!isLoading" v-model="tab">
      <v-tab-item class="pt-0 pa-5">
        <div class="firstTabTitle">Categorías de Servicios</div>
        <div class="firstTabSubtitle mt-3">
          {{ getCompanyCategories.length }} Categorías y {{ getCompanyServices.length }} Servicios en total
        </div>
        <v-tabs vertical class="mt-6" slider-color="transparent" v-model="verticalTab">
          <v-tab
            v-for="(category, index) in getCompanyCategories"
            :key="index"
            style="max-width: 500px"
            :class="index == verticalTab ? 'activeTab tabsBorder' : 'inactiveTab tabsBorder'"
            >{{ category.name }}
          </v-tab>
          <v-tabs-items v-model="verticalTab">
            <v-tab-item v-for="category in getCompanyCategories" :key="category.id">
              <div class="tabItemTitle">
                {{ category.services.length }} Servicios y {{ category.specialistsAmount }} Especialistas en
                {{ category.name }}
              </div>
              <v-row>
                <v-col cols="4" xl="3" v-for="service in category.services" :key="service.id">
                  <v-card class="serviceCard">
                    <Chat class="serviceCardIcon" />
                    <span class="serviceCardTitle">
                      {{ service.name }}
                    </span>
                    <UserCertification class="serviceCardIconUser" />
                    <span class="serviceCardSpecialists">
                      {{ handleSpecialistsByService(service.id) }}
                      Especialistas
                    </span>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-tab-item>
      <v-tab-item class="pt-0 pa-5">
        <div class="graph-container">
          <HierarchicalBarChart
            v-if="hierarchicalBarData.children.length > 0"
            :data="hierarchicalBarData"
            :width="640"
          />
        </div>
      </v-tab-item>
      <v-tab-item class="pt-0 pa-5">
        <div class="graph-container">
          <ZoomableCirclePackingChart
            v-if="zoomableCirclePackingData.children.length > 0"
            :data="zoomableCirclePackingData"
            :width="640"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-fab-transition>
      <v-btn color="#7319D5" dark fixed bottom right fab @click="goToProjects()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import ZoomableCirclePackingChart from "@/components/charts/ZoomableCirclePackingChart";
import HierarchicalBarChart from "@/components/charts/HierarchicalBarChart";
import RankingService from "@/services/rankingService";
import Loader from "@/components/Loader";
import Breadcrumbs from "@/components/Breadcrumbs";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    HierarchicalBarChart,
    ZoomableCirclePackingChart,
    Loader,
    Breadcrumbs,
  },

  data: () => ({
    tab: 0,
    verticalTab: 0,
    zoomableSunburstData: {},
    hierarchicalBarData: {},
    zoomableCirclePackingData: {},
    isLoading: true,
    breadcrumbs: [
      {
        text: "Inicio",
        disabled: false,
        exact: true,
        to: {
          name: "home",
        },
      },
      {
        text: "Ranking",
        disabled: true,
        exact: true,
        to: {
          name: "ranking",
        },
      },
    ],
  }),

  async created() {
    await this.getRanking();
  },

  methods: {
    ...mapActions("CompanyStore", ["setCategories", "setServices", "setCompanySpecialists"]),
    async getRanking() {
      try {
        await this.setCategories({
          clientId: this.getClientId,
          withSpecialistsAmount: true,
        });
      } catch (error) {
        console.log(error);
      }
      try {
        await this.setServices({
          clientId: this.getClientId,
          withSpecialistsAmount: true,
        });
      } catch (error) {
        console.log(error);
      }

      await this.setCompanySpecialists();
      this.zoomableSunburstData = {
        name: "Categorías",
        children: [],
      };

      this.hierarchicalBarData = {
        name: "Sectores",
        children: [],
      };

      this.zoomableCirclePackingData = {
        name: "Sectores",
        children: [],
      };

      RankingService.getByTrajectory()
        .then((ranking) => {
          this.zoomableSunburstData.children = ranking;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = true;
          console.log(err);
        });

      RankingService.getByNumberOfProjects()
        .then((ranking) => {
          this.hierarchicalBarData.children = ranking;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = true;
          console.log(err);
        });

      RankingService.getBySize()
        .then((ranking) => {
          this.zoomableCirclePackingData.children = ranking;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = true;
        });
    },

    goToProjects() {
      this.$router.push("/project-foundation");
    },
    handleSpecialistsByService(id) {
      if (id != null) {
        const services = this.getCompanyServices;
        const selected = services.find((e) => e.id == id);
        return selected.specialistsAmount;
      } else {
        return 0;
      }
    },
  },
  computed: {
    ...mapGetters("CompanyStore", ["getCompanyCategories", "getCompanyServices", "getCompanySpecialists"]),
    ...mapGetters("UserStore", ["getClientId"]),
  },
  watch: {
    verticalTab() {},
  },
};
</script>

<style scoped lang="scss">
.firstTabTitle {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
}
.firstTabSubtitle {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #7319d5;
}
.activeTab {
  display: flex;
  justify-content: flex-start;
  text-transform: capitalize !important;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #ffffff !important;
  background: #119a24;
}
.inactiveTab {
  display: flex;
  justify-content: flex-start;
  text-transform: capitalize !important;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #333333 !important;
  background: transparent;
}
.tabsBorder {
  margin-right: 10px;
  border-right: 1px solid #cccccc;
}
.tabItemTitle {
  font-family: "Josefin Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 15px;
}
.tabs-title {
  text-transform: none !important;
  font-size: 18px;
}
.serviceCard {
  position: relative;
  width: 260px;
  height: 140px;
  background: #ffffff;
  border: 1px solid #7319d5;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  .serviceCardTitle {
    position: absolute;
    top: 50px;
    left: 15px;
    font-family: "Roboto Condensed", sans-serif;
    font-style: bold;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #333333;
  }
  .serviceCardIcon {
    position: absolute;
    left: 17px;
    top: 10px;
    color: #7319d5;
  }
  .serviceCardIconUser {
    position: absolute;
    left: 16px;
    bottom: 14px;
    color: #119a24;
    margin-top: 10px;
  }
  .serviceCardSpecialists {
    position: absolute;
    left: 44px;
    bottom: 14px;
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;

    /* identical to box height, or 193% */

    /* Tway / Gris Oscuro */
    color: #333333;
  }
}
.graph-container {
  max-width: 640px;
  margin: 0px auto;
}
.ranking-title {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 150%;
  color: #333333;
}
.ranking-alert {
  width: 100%;
  display: flex;
  justify-content: center;
  > div {
    max-width: 640px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #7319d5;
    color: #7319d5;
    box-sizing: border-box;
    border-radius: 20px;
    margin-bottom: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    > span {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
</style>
