import * as d3 from "d3";

export default function render(element, { data, width }) {
  let height = width;

  const color = d3.scaleOrdinal(d3.quantize(d3.interpolate("#4A148C", "#af96cd"), data.children.length + 1));

  const pack = (data) =>
    d3.pack().size([width, height]).padding(3)(
      d3
        .hierarchy(data)
        .sum((d) => d.value)
        .sort((a, b) => b.value - a.value)
    );

  const root = pack(data);
  let focus = root;
  let view;

  const svg = d3
    .select(element)
    .append("svg")
    .attr("xmlns:xhtml", "http://www.w3.org/1999/xhtml")
    .attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
    .style("display", "block")
    .style("margin", "0 -14px")
    .style("background", "white")
    .style("cursor", "pointer")
    .on("click", (event) => zoom(event, root));

  const node = svg
    .append("g")
    .selectAll("circle")
    .data(root.descendants())
    .join("circle")
    .attr("fill", (d, i) => (d.children ? color(i + 1) : "white"))
    .attr("opacity", (d, i) => (i === 0 ? 0.2 : 1))
    .attr("pointer-events", (d) => (!d.children ? "none" : null))
    .on("mouseover", function () {
      d3.select(this).attr("stroke", "#16c62e");
    })
    .on("mouseout", function () {
      d3.select(this).attr("stroke", null);
    })
    .on("click", (event, d) => focus !== d && (zoom(event, d), event.stopPropagation()));

  const label = svg
    .append("g")
    .selectAll("foreignObject")
    .data(root.descendants())
    .join("foreignObject")
    .style("fill-opacity", (d) => (d.parent === root ? 1 : 0))
    .style("display", (d) => (d.parent === root ? "inline" : "none"));

  label
    .append("xhtml:div")
    .attr("class", "d3-circle-packing-tooltip")
    .html((d) => d.data.name);

  zoomTo([root.x, root.y, root.r * 2]);

  function zoomTo(v) {
    const k = width / v[2];

    view = v;

    label.attr("x", function (d) {
      return (d.x - v[0]) * k - this.children[0].offsetWidth / 2;
    });
    label.attr("y", function (d) {
      return (d.y - v[1]) * k - this.children[0].offsetHeight / 2;
    });
    label.attr("width", function () {
      return this.children[0].offsetWidth;
    });
    label.attr("height", function () {
      return this.children[0].offsetHeight;
    });

    node.attr("transform", (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
    node.attr("r", (d) => d.r * k);
  }

  function zoom(event, d) {
    focus = d;

    const transition = svg
      .transition()
      .duration(event.altKey ? 7500 : 750)
      .tween("zoom", () => {
        const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
        return (t) => zoomTo(i(t));
      });

    label
      .filter(function (d) {
        return d.parent === focus || this.style.display === "inline";
      })
      .transition(transition)
      .style("fill-opacity", (d) => (d.parent === focus ? 1 : 0))
      .on("start", function (d) {
        if (d.parent === focus) this.style.display = "inline";
      })
      .on("end", function (d) {
        if (d.parent !== focus) this.style.display = "none";
      });
  }

  return svg.node();
}
